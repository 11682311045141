import MainLayout from '../../layouts/MainLayout';
import SecondaryHeading from '../../components/SecondaryHeading';
import StatsResume from '../../components/StatsResume';
import { useContext, useEffect, useState } from 'preact/hooks';
import { AppContext, TablePos } from '../../app';
import Axios from 'axios';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextBlock } from '../../components/Type/Title';
import { StrongNumber } from '../../components/Type/StrongNumber'

const ProfileView = () => {

    const { widgetInfo, fetchInfo } = useContext(AppContext)

    return (
        <MainLayout>
            <SecondaryHeading name={'My stats'} />
            <Resume>
                <div className="section-label">INDICATORS</div>
                <StatsGridBlock>
                    <StatItem>
                        <div className="inner">
                            <FontAwesomeIcon icon={['fas', 'users-class']} className="icon" style={{ color: '#F79595' }} />
                            <StrongNumber>{widgetInfo?.me.stats.assistance.timesPresent}</StrongNumber>
                        </div>
                        <TextBlock style={{ textAlign: 'center' }} className="label">Total<br />attendance</TextBlock>
                    </StatItem>
                    <StatItem>
                        <div className="inner">
                            <FontAwesomeIcon icon={['fas', 'trophy']} className="icon" style={{ color: '#47A5FF' }} />
                            <StrongNumber>{widgetInfo?.me.stats.timesFacilitator}</StrongNumber>
                        </div>
                        <TextBlock style={{ textAlign: 'center' }} className="label">Times<br />facilitator</TextBlock>
                    </StatItem>
                    <StatItem>
                        <div className="inner">
                            <FontAwesomeIcon style={{ color: '#FFCD00' }} icon={['fas', 'egg']} className="icon" />
                            <StrongNumber>01</StrongNumber>
                        </div>
                        <TextBlock style={{ textAlign: 'center' }} className="label">Given<br />recognitions</TextBlock>
                    </StatItem>
                    <StatItem>
                        <div className="inner">
                            <FontAwesomeIcon icon={['fas', 'egg']} className="icon" />
                            <StrongNumber>01</StrongNumber>
                        </div>
                        <TextBlock style={{ textAlign: 'center' }} className="label">Received<br />recognitions</TextBlock>
                    </StatItem>

                </StatsGridBlock>
            </Resume>
        </MainLayout>
    )
}

export default ProfileView

const Resume = styled.div`
    flex-grow:1;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    /* border:1px solid red; */
    padding:20px 30px;
    .section-label{
        font-size:11px;
        font-weight:bold;
        letter-spacing:0.1rem;
        margin-bottom:10px;
    }
`

const StatsGridBlock = styled.div`
    display:flex;
    flex-flow: row wrap;
`

const StatItem = styled.div`
    flex-basis:calc(50% - 20px);
    flex-grow:1;
    margin:10px 10px 0 10px;
    text-align:center;
    width:120px;
    /* background-color:blue; */
    .inner{
        border: 1px solid #F3F3F3;
        box-shadow: 0 0 50px 0 rgba(0,0,0,0.05);
        border-radius: 25px;
        /* background-color:red; */
        padding:30px;
        height:120px;
        .icon{
            font-size:32px;
            margin-bottom:10px;
        }
    }
`
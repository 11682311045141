import MainHeading from '../../components/MainHeading'
import CircularTable from '../../components/Table/CircularTable'
import ListTable from '../../components/Table/ListTable'
import MainFooter from '../../components/MainFooter'
import MainLayout from '../../layouts/MainLayout'
import { useContext, useEffect, useState } from 'preact/hooks'
import { AppContext } from '../../app'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'

const TableView = () => {

    const [ready, setReady] = useState<boolean>(false)
    const { widgetInfo, isFetching, viewType } = useContext(AppContext)
    const navigate = useNavigate()
    const { search } = useLocation()

    // If widget info is ready, set ready this page
    useEffect(() => {
        if (widgetInfo) { 
            setReady(true)
            if(widgetInfo.me.tutorialWidgetCompleted === false){
                navigate('/tutorial' + search)
            }
        }
    }, [widgetInfo])


    if (!ready) return <MainLayout centered={true}><div>Loading...</div></MainLayout>

    return (
        <MainLayout>
            <MainHeading me={widgetInfo?.me} team={widgetInfo?.team} />
            {viewType === 'table' ? (
                <CircularTableContainer className="circular-container">
                    <CircularTable widgetInfo={widgetInfo} table={widgetInfo?.table} meeting={widgetInfo?.meeting} />
                </CircularTableContainer>
            ) : (
                <ListTableContainer>
                    <ListTable table={widgetInfo?.table} meeting={widgetInfo?.meeting} />
                </ListTableContainer>
                )
            }
            {viewType === 'table' && <MainFooter isFetching={isFetching} table={widgetInfo?.table} />}
        </MainLayout>
    )
}

export default TableView

const CircularTableContainer = styled.div`
    /* border:1px solid red; */
    flex:1;
    display:flex;
    flex-direction:column;
    justify-content:center;
`

const ListTableContainer = styled.div`
    /* border:1px solid yellow; */
    flex:1;
    
`
export const colors = {
    eggColor: '#FFCD00',
    darkGreyEgg: '#37474F',
    blueEgg: '#00AAFF',
    washedGreyEgg: '#879DA7',
    mediumGreyBg: '#D2D2D2',
    lightGreyBg: '#F8F8F8',
    paragraphColor: '#37474F',
    separadorLineas: '#EAEAEA',
    borders: '#F3F3F3',

    dhPrimary: '#4349B5',
    dhSecondary: '#3674E6',
    dhWashed: '#CAE1FF'
}
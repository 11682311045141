import styled from 'styled-components';
import { SecondaryVoteButton } from './SecondaryVoteButton';

export const SecondaryVoteButtonSm = styled(SecondaryVoteButton)`
    font-size: 12px;
    height:33px;
    width:100%;
    box-shadow:none;
    text-transform: capitalize;
    letter-spacing:0.01rem;
    .icon{
        margin-right:6px;
        font-size:14px;
    }
`
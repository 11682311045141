import { ComponentChildren, FunctionComponent } from 'preact';
import styled, { css } from 'styled-components';

interface IProps {
    centered?: boolean,
    children: ComponentChildren
}

const MainLayout = ({ children, centered }: IProps) => {
    return (
        <Layout centered={centered}>
            {children}
        </Layout>
    )
}

export default MainLayout


const Layout = styled.div<{ centered: boolean }>`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${({ centered }) => centered && css`
        justify-content: center;
        align-items:center;
    `}
`
import { colors } from '../../style/variables';
import styled, { css } from 'styled-components';

export const WhiteButton = styled.button<{ 
    width?: number, 
    height?: number, 
    size?: 'regular' | 'sm' 
}>`
    display:flex;
    align-items:center;
    justify-content:center;
    text-transform:uppercase;
    background: #FFFFFF;
    border: 1px solid #F3F3F3;
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.05);
    border-radius: 8px;
    text-transform: auto;
    font-size: 11px;
    color: #37474F;
    letter-spacing: 0.1rem;
    text-align: center;
    font-weight:bold;
    transition: background-color .3s ease;    
    width:${({ width }) => width + '%' ?? '100%'};
    height: 50px;
    padding: 15px;
    .icon{
        color: #37474F;
        margin-right:10px;
        font-size:20px;
        display:inline;
        &.egg-color{
            color:${colors.eggColor}
        }
    }
    .vote-icon{
        &.active{
            color:#FF7F7F;
        }
    }
    &:hover:enabled{
        background-color:#EAEAEA;
        color:${colors.darkGreyEgg};
        cursor:pointer;
        .icon{
            color:${colors.darkGreyEgg};
        }
    }
    &:disabled{
        opacity:0.5;
    }
    ${({ active }) => active && css`
        background-color:${colors.dhPrimary};
        color:white;
        .icon{
            color:white !important;
        }
        &:disabled{
            opacity:1;
        }
    `}
`
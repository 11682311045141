import { useContext, useEffect, useState } from 'preact/hooks'
import { SecondaryVoteButtonSm } from '../../components/Buttons/SecondaryVoteButtonSm'
import { WhiteButton } from '../../components/Buttons/WhiteButton'
import { BlueButton } from '../../components/Buttons/BlueButton'
import styled from 'styled-components'
import { AppContext } from '../../app'
import MainLayout from '../../layouts/MainLayout'
import { MainTitle, LeadTextBlock } from '../../components/Type/Title'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from '../../style/variables'
import AvatarEgg from '../../components/Avatar/AvatarEgg'
import { useVoting } from '../../hooks/useVoting'
import ReactGA from 'react-ga'
import { Text } from 'preact-i18n';

const MandatoryVoteView = () => {

    const { widgetInfo } = useContext(AppContext)
    const [memberToVote, setMemberToVote] = useState<string | undefined>(undefined)
    const { voteStudent, isVoting } = useVoting()

    useEffect(() => {
        ReactGA.modalview('/mandatory-vote');
    },[ReactGA])

    return (
        <MainLayout>
            <Resume>
                <MainTitle style={{ marginTop: '0' }}><Text id="mandatory_vote.title"></Text></MainTitle>
                <LeadTextBlock style={{ margin: '0px 0px 10px 0px', textAlign: 'center' }}>
                <Text id="mandatory_vote.msg"></Text>
                </LeadTextBlock>
                <VoteList>
                    {
                        widgetInfo?.table.positions
                            // Delete myself from list since I can't vote myself
                            .filter((stu) => stu._profileId !== widgetInfo.me.profile._id)
                            .map((dent) => {
                                return (
                                    <ListItem>
                                        <div className="user-data">
                                            <AvatarEgg 
                                            title={`${dent._userId.name} ${dent._userId.lastname}`} 
                                            src={dent._userId.urlImage} 
                                            size={38} />
                                            <LeadTextBlock style={{ marginLeft: '10px' }}>{dent._userId.name} {dent._userId.lastname}</LeadTextBlock>
                                        </div>
                                        <SecondaryVoteButtonSm
                                            style={{ width: '118px', textTransform:'capitalize', letterSpacing:'0.01rem', fontSize:'12px' }}
                                            title={`Select ${dent._userId.name} ${dent._userId.lastname}`}
                                            // If list student is already selected
                                            voted={dent._profileId === memberToVote}
                                            // If selected, unselect when click
                                            onClick={() => setMemberToVote(prev => prev === dent._profileId ? undefined : dent._profileId)}>
                                            <Text id="actions.recognize"/>
                                        </SecondaryVoteButtonSm>
                                    </ListItem>
                                )
                            })
                    }
                </VoteList>
                <BlueButton
                    style={{ marginTop: '10px', width: '100%' }}
                    className="join-button"
                    disabled={!memberToVote}
                    onClick={memberToVote && (() => voteStudent(memberToVote))}
                    aria-label="Recognize student"
                    >
                    {isVoting && <FontAwesomeIcon icon={['fal', 'spinner']} spin />}
                    {!isVoting && <Text id="control.accept"/>}
                </BlueButton>
                <WhiteButton
                    style={{ marginTop: '10px', width: '100%' }}
                    className="join-button"
                    onClick={() => voteStudent('omit')}
                    aria-label="Omit recognition"
                    >
                    {isVoting && <FontAwesomeIcon icon={['fal', 'spinner']} spin />}
                    {!isVoting && <Text id="control.omit"/>}
                </WhiteButton>
            </Resume>
        </MainLayout>
    )
}

export default MandatoryVoteView


const Resume = styled.div`
    flex-grow:1;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    /* border:1px solid red; */
    padding:20px 30px;
`

export const VoteList = styled.ul`
    list-style-type:none;
    padding:0;
    margin:0;
    width:100%;
`

export const ListItem = styled.li`
    padding:10px 0;
    /* margin:10px 0; */
    display:flex;
    align-items:center;
    justify-content:space-between;
    text-transform:capitalize;
    &:not(:last-child){
        border-bottom:1px solid ${colors.separadorLineas};
    }
    .user-data{
        display:flex;
        align-items:center;
        flex:1;
    }
`
import { useLocation, useNavigate } from 'react-router'

const StatsView = () => {

    const navigate = useNavigate()
    const { search } = useLocation()


    return (
        <div>
            I'm a stats view
            <button onClick={() => navigate('/' + search)}>Navigate</button>
        </div>
    )
}

export default StatsView

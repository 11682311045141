import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from '../style/variables';
import styled from 'styled-components'
import { TextBlock } from './Type/Title'
import { IWidgetTable } from 'src/app';
import { useVoting } from '../hooks/useVoting';
import { PrimaryVoteButton } from '../components/Buttons/PrimaryVoteButton'
import { Link } from 'react-router-dom'
import { LinkWithQuery } from './LinkWithQuery';
import { Text } from 'preact-i18n';
import { useLocation } from 'react-router-dom'

interface Props {
    table: IWidgetTable | undefined,
    isFetching: boolean
}

const MainFooter = ({ table }: Props) => {

    const { voteTable, isVoting } = useVoting()

    return (
        <Footer>
            <div className="table-vote">
                <PrimaryVoteButton
                    aria-label="Like this team"
                    onClick={() => voteTable()}
                    style={{width: '100%', marginBottom:'3px'}}
                    voted={table?.voteData?.hasVoted}
                >
                    {isVoting !== undefined 
                        ? (<FontAwesomeIcon className="icon" icon={['fal', 'spinner']} spin />)
                        : (<FontAwesomeIcon className={"icon"} icon={table?.voteData?.hasVoted ? ['fas', 'heart'] : ['fal', 'heart']} />)
                    }
                    <Text id="main_footer.like_team">Home</Text>
                </PrimaryVoteButton>
            </div>
            <div className="text">
                <TextBlock style={{color:'black'}}>
                <Text id="main_footer.tutorial_help"></Text>
                <LinkWithQuery to="/tutorial" >
                    <Text id="main_footer.tutorial_link"></Text>
                </LinkWithQuery></TextBlock>
            </div>
        </Footer>
    )
}

export default MainFooter


const Footer = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    flex-direction:column;
    padding:5px 25px 13px;
    margin-top:5px;
    .text{
        text-align:center;
        padding:0 20px;
        h2{
            margin-bottom:0px;
        }   
        p{
            margin-top:5px;
            color:${colors.washedGreyEgg}
        }
    }
    .table-vote{
        width:100%;
        cursor:pointer;
        position:relative;
    }
`
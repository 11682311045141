import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faCheckSquare,
    faCoffee,
    faUserCircle,
    faHeart as falHeart,
    faArrowLeft,
    faMailbox,
    faEgg as falEgg,
    faSpinner,
    faVideo as falVideo,
    faCamera,
    faListUl,
    faDrawCircle as falDrawCircle,
    faCircleNotch,
    faTimes
} from '@fortawesome/pro-light-svg-icons'

import {
    faUsersClass,
    faTrophy,
    faEgg as fasEgg,
    faHeart as fasHeart,
    faBell,
    faVideo as fasVideo,
    faDrawCircle as fasDrawCircle,
    faCheck,
    faMedal,
    faUserCheck as fasUserCheck,
    faUsers
} from '@fortawesome/pro-solid-svg-icons'

library.add(
    faCheckSquare,
    faCoffee,
    faBell,
    faUserCircle,
    falHeart,
    fasHeart,
    faArrowLeft,
    faMailbox,
    faUsersClass,
    faTrophy,
    falEgg,
    fasEgg,
    faSpinner,
    falVideo,
    fasVideo,
    faCamera,
    faListUl,
    falDrawCircle,
    fasDrawCircle,
    faCircleNotch,
    faCheck,
    faMedal,
    fasUserCheck,
    faUsers,
    faTimes
)
import styled from "styled-components"
import { StrongNumber } from '../components/Type/StrongNumber'
import { TextBlock } from '../components/Type/Title'
import { SecondaryVoteButton } from './Buttons/SecondaryVoteButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AvatarEgg from '../components/Avatar/AvatarEgg'
import { colors } from "../style/variables"
import { IUserStats, IUserStatsResponse, TablePos } from "../app"
import { useEffect, useState } from "preact/hooks"
import { useS3Upload } from "../hooks/useS3Upload"
import axios from "axios"
import { useVoting } from '../hooks/useVoting'
import Gr from '../assets/given-recogn.svg'
import Rr from '../assets/received-recogn.svg'
import { Text, MarkupText } from 'preact-i18n';

interface IProps {
    user: TablePos | undefined,
    isUserVoted: boolean,
    isMe: boolean,
    isUserFacilitator: boolean
}

const StatsResume = ({ user, isUserVoted, isMe, isUserFacilitator }: IProps) => {
    

    const { S3Upload } = useS3Upload();
    const [userStats, setUserStats] = useState<IUserStats | undefined>(undefined)
    const { voteStudent, isVoting } = useVoting()

    const fetchStats = async () => {
        const res = await axios.get<IUserStatsResponse>(`${process.env.PREACT_APP_WIDGET_API}/v1/user/stats/${user?._profileId}`)
        setUserStats(res.data.data)
    }

    useEffect(() => {
        fetchStats()
    }, [user])

    const handleFileSelect = async (e: any) => {
        e.preventDefault()
        const selectedFile = e?.target?.files?.[0];
        await handleUpload(selectedFile)
    }

    const handleUpload = async (fileToUpload: Blob) => {
        console.log('uploading file')
        const filename = `users/${user?._userId._id}/${user?._userId._id}-profile-image.jpg`
        const urlImage = await S3Upload(fileToUpload, filename)
        console.log('url', urlImage)
        try {
            await axios.patch(`${process.env.PREACT_APP_WIDGET_API}/v1/user`, {
                urlImage
            })
        } catch (error) {
            console.log('Error updating user on db.', error)
        }
    }

    return (
        <Resume>
            <AvatarBlock>
                <AvatarEgg
                    title={`${user?._userId.name} ${user?._userId.lastname}`}
                    size={110}
                    src={user?._userId?.urlImage ?? ''}
                    isMe={isMe}
                    handleFileSelect={handleFileSelect}
                    facilitator={isUserFacilitator}
                />
                {!isMe && <SecondaryVoteButton
                    disabled={isUserVoted || isMe}
                    voted={isUserVoted}
                    onClick={() => voteStudent(user?._profileId ?? '')}
                    aria-label={isUserVoted ? 'You already voted for this student' : 'Vote for this student'}
                    >
                    {isVoting && <FontAwesomeIcon className="icon" icon={['fal', 'spinner']} spin />}
                    {isMe ? <Text id="stats.cannot_vote" /> : isUserVoted ? <Text id="stats.recognized" /> : <Text id="stats.recognize" />}
                </SecondaryVoteButton>}
            </AvatarBlock>
            {userStats ? (
                <StatsGridBlock>
                    <StatItem>
                        <div className="inner">
                            <FontAwesomeIcon icon={['fas', 'user-check']} className="icon" style={{ color: '#CAE1FF' }} />
                            <StrongNumber>{userStats?.assistance.timesPresent}</StrongNumber>
                        </div>
                        <TextBlock style={{ textAlign: 'center' }} className="label"><MarkupText id="stats.assistance"/></TextBlock>
                    </StatItem>
                    <StatItem>
                        <div className="inner">
                            <FontAwesomeIcon icon={['fas', 'medal']} className="icon" style={{ color: '#3674E6' }} />
                            <StrongNumber>{userStats?.timesFacilitator}</StrongNumber>
                        </div>
                        <TextBlock style={{ textAlign: 'center' }} className="label"><MarkupText id="stats.times_facilitator"/></TextBlock>
                    </StatItem>
                    <StatItem>
                        <div className="inner">
                            <img src={Rr} width={36} alt="" />
                            <StrongNumber>{userStats?.receivedVotes}</StrongNumber>
                        </div>
                        <TextBlock style={{ textAlign: 'center' }} className="label"><MarkupText id="stats.taken_votes"/></TextBlock>
                    </StatItem>
                    <StatItem>
                        <div className="inner">
                            <img src={Gr} width={36} alt="" />
                            <StrongNumber>{userStats?.givenVotes}</StrongNumber>
                        </div>
                        <TextBlock style={{ textAlign: 'center' }} className="label"><MarkupText id="stats.given_votes"/></TextBlock>
                    </StatItem>
                </StatsGridBlock>
                ) : (
                <Stats>
                    <FontAwesomeIcon className="icon" icon={['fal', 'spinner']} spin />
                </Stats>
            )}
        </Resume>
    )
}

export default StatsResume

const Resume = styled.div`
    flex-grow:1;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    /* border:1px solid red; */
    padding:20px 30px;
`

const AvatarBlock = styled.div`
    width:100%;
    /* background-color:blue; */
    margin-bottom:20px;
    border-radius:10px;
    display:flex;
    flex-direction:column;
    align-items:center;
    button{
        margin-top:20px;
    }
`
const Stats = styled.div`
    display:flex;
    justify-content:space-around;
    margin:30px 0 30px ;
    /* background-color:red; */
    .stat-item{
        text-align:center;
        padding:0 20px;
        .icon{
            margin-top: 5px;
            margin-bottom:10px;
            font-size:20px;
        }
        .class{
            color: #F79595
        }
        .trophy{
            color: #47A5FF
        }
        .egg-up{
            color: ${colors.eggColor}
        }
        .egg-down{
            color: ${colors.darkGreyEgg}
        }
    }
`




const StatsGridBlock = styled.div`
    display:flex;
    flex-flow: row wrap;
`

const StatItem = styled.div`
    flex-basis:calc(50% - 30px);
    flex-grow:1;
    margin:10px 15px 0 15px;
    text-align:center;
    width:120px;
    /* background-color:blue; */
    .inner{
        border: 1px solid #F3F3F3;
        box-shadow: 0 0 50px 0 rgba(0,0,0,0.05);
        border-radius: 25px;
        /* background-color:red; */
        padding:30px;
        height:120px;
        .icon{
            font-size:32px;
            margin-bottom:10px;
        }
    }
`
import styled from 'styled-components';
import { WhiteButton } from './WhiteButton';
import { colors } from '../../style/variables'

export const BlueButton = styled(WhiteButton)`
    background-color: ${colors.dhPrimary};
    color:white;
    &:hover{
        background-color:${colors.dhSecondary} !important;
        color:white !important;
    }
`
import styled, { css } from 'styled-components'
import { colors } from '../../style/variables'

export const PrimaryVoteButton = styled.button`
    display:flex;
    align-items:center;
    justify-content:center;
    background: #FFFFFF;
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.05);
    border: 1px solid #F3F3F3;
    border-radius: 8px;
    text-transform: uppercase;
    font-size: 12px;
    color: #37474F;
    letter-spacing: 0.05rem;
    text-align: center;
    font-weight:bold;
    transition: background-color .3s ease;
    height:50px;
    padding: 0 10px;
    &:hover:enabled{
        background-color:#EAEAEA;
        color:${colors.darkGreyEgg};
        cursor:pointer;
        .icon{
            color:${colors.darkGreyEgg};
        }
    }
    &:disabled{
        opacity:0.5;
    }
    .icon{
        color: #37474F;
        margin-right:10px;
        font-size:20px;
        display:inline;
    }
    ${({ voted }) => voted && css`
        .icon{
            color:${colors.dhPrimary};
        }
    `}
`
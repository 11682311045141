import AvatarEgg from '../Avatar/AvatarEgg';
import styled from 'styled-components';
import { IWidgetInfo, IWidgetMeeting, IWidgetTable } from '../../app';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from '../../style/variables'
import { Text } from 'preact-i18n';
import CircularCenter from '../../assets/circular_center.png'
import { useLocation, useNavigate } from 'react-router-dom';

interface TableProps {
    diameter: number,
    initial: number,
    clockwise: boolean,
    table: IWidgetTable | undefined;
    meeting: IWidgetMeeting | null | undefined,
    widgetInfo?: IWidgetInfo;
}

const CircularTable = ({ initial, diameter, clockwise, table, widgetInfo }: TableProps) => {

    const navigate = useNavigate()
    const { search } = useLocation()

    /** Given a phase (in tau units) return the x and y component */
    const toPolar = (phase: number): [number, number] => [
        Math.sin(phase * 2 * Math.PI),
        Math.cos(phase * 2 * Math.PI)
    ]

    return (
        <TableContainer className="es-esta?">
            <Workgroup>
                <FontAwesomeIcon className="icon" icon={['fas','users']}/>
                <Text id="circular_table.team_number" fields={{tableNumber: table?.tableNumber}}></Text>
            </Workgroup>
            <Table >
                {
                    table?.positions.map((item, i) => {
                        const [x, y] = toPolar(initial / 360 + i / table.positions.length * (clockwise ? -1 : 1))
                        return (
                            <TableItem
                                as="button"
                                x={x}
                                y={y}
                                diameter={diameter}
                                key={item._profileId}
                                onClick={() => navigate(`/user/${item._userId._id}` + search)}
                                aria-label="See student stats"
                                >
                                <div title={`${item._userId.name} ${item._userId.lastname}`}>
                                    <AvatarEgg
                                        title={`${item._userId.name} ${item._userId.lastname}`}
                                        facilitator={table.facilitator === item._profileId}
                                        src={item._userId.urlImage}
                                        size={58}
                                        isMe={widgetInfo?.me.profile._id === item._profileId}
                                        hasTooltip={true}
                                    />
                                </div>
                            </TableItem>
                        )
                    })
                }
                {/* element in the center */}
                
                <img src={CircularCenter} width={80} alt="" />
            </Table>
        </TableContainer>
    )
}

CircularTable.defaultProps = {
    diameter: 65,
    initial: 90,
    clockwise: true,
}

export default CircularTable

const Workgroup = styled.div`
    .icon{
        margin-right:5px;
    }
    text-align:center;
    padding:15px 0 0px;
    font-size:12px;
    color:${colors.dhSecondary};
    letter-spacing:0.5px;
    text-transform:uppercase;
    font-weight:bold;
`

const BtnMeeting = styled.button`
    width:74px;
    height:74px;
    border-radius: 74px;
    display:flex;
    align-items:center;
    justify-content:center;
    background-image: linear-gradient(180deg, #FFFFFF 0%, #EFEFEF 99%);
    border: 1px solid #F3F3F3;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.05);
    cursor:pointer;
    transition:all 0.3 ease;
    .icon{
        color:${colors.dhSecondary};
        font-size:25px;
    }
    &:not(:disabled):hover{
        background-image: linear-gradient(180deg, #3674E6 0%, #3674E6 100%);
        .icon{
            color:white;
        }
    }
    &:disabled{
        opacity:0.8;
    }
`

const TableContainer = styled.div`
    /* border:1px solid pink; */
`


const Table = styled.div`
    width: 310px;
    min-height: 310px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color:red; */
    margin-bottom:-20px;
    .center{
        display:inline-block;
        position:relative;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        transform-origin: bottom;
        .meet-icon{
            position:absolute;
            bottom:0;
            right:-15px;
        }
        &.bigger:hover{
            cursor:pointer;
            animation-name: bounce;
            animation-timing-function: ease;
        }
        @keyframes bounce {
            0%   { transform: translateY(0); }
            50%  { transform: translateY(-5px); }
            100% { transform: translateY(0); }
        }
    }
`

const TableItem = styled.div`
    position:absolute;
    top: ${({ x, diameter }) => (50 - diameter / 2 * x) + '%'};
    left: ${({ y, diameter }) => (50 + diameter / 2 * y) + '%'};
    transform: translate(-50%,-50%);
    background:none;
    border:none;
    transition:opacity .2s ease;
    &:hover{
        cursor:pointer;
        opacity:.5;
    }
`

import { Link } from 'react-router-dom'
import { useEffect} from 'preact/hooks'
import styled from 'styled-components'
import { MainTitle } from './Type/Title'
import { colors } from '../style/variables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppContext, IWidgetInfo } from '../app'
import { useContext, useState } from 'preact/hooks';
import { Text } from 'preact-i18n';
import { LinkWithQuery } from './LinkWithQuery'

const MainHeading = ({ me, team }: Partial<IWidgetInfo>) => {

    const [switchLabelShow, setSwitchLabelShow] = useState(false)
    const { viewType, setViewType } = useContext(AppContext)
    const [completeName, setCompleteName] = useState(`${me?.name} ${me?.lastname}`)
    // const { t } = useTranslation(['translation'])
    
    useEffect(() => {
        if(completeName.length > 20){
            setCompleteName(completeName.slice(0,20) + '...')
        }
    },[me])
    
    return (
        <Heading>
            <MainTitle style={{margin:'0',padding:'0'}} className="middle"><LinkWithQuery to={`/user/${me?._id}`}>{`${me?.name ? completeName : 'Name'}`}</LinkWithQuery></MainTitle>
            <span style={{marginTop:'5px', textTransform:'capitalize', fontSize:'13px', letterSpacing:'0.13px'}}>{team && team?.course?.name.length > 30 ? `${team?.course.name.substring(0,30)}...` : `${team?.course.name}` }</span>
            <SwitcherContainer>
                <ViewSwitcher
                    aria-label={`${viewType === 'list' ? 'Switch to circular view' : 'Switch to list view'}`}
                    onMouseEnter={() => setSwitchLabelShow(true)}
                    onMouseLeave={() => setSwitchLabelShow(false)}
                    onClick={() => setViewType(prev => prev === 'list' ? 'table' : 'list')}>
                    <FontAwesomeIcon className="icon" icon={viewType === 'list' ? ['fal', 'circle-notch'] : ['fal', 'list-ul']} />
                    {switchLabelShow && <span><Text id="control.switch_view"></Text></span> }
                </ViewSwitcher>
            </SwitcherContainer>
        </Heading >
    )
}

export default MainHeading

const ViewSwitcher = styled.button`
    width:auto;
    background:none;
    border:none;
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:white;
    border:1px solid ${colors.borders};
    border-radius:5px;
    width:34px;
    width:34px;
    padding:4px;
    .icon{
        font-size:16px;
    }
    span{
        margin-left:7px;
        font-size:12px;
        color:${colors.washedGreyEgg}
    }
    &:hover{
        cursor:pointer;
        width:auto;
    }
`

const SwitcherContainer = styled.div`
    position:absolute;
    bottom:-15px;
    right:0;
    padding:0 35px;
    /* background-color:red; */
`

const Heading = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:center;
    border-bottom:2px solid ${colors.borders};
    padding:18px 25px;
    height:85px;
    position:relative;
    .left{
        width:20%;
    }
    .middle{
        flex:1;
        padding:0 20px;
        text-transform:capitalize;
        line-height: 1.6rem;
        text-align:center;
    }
    .right{
        width:15%;
    }
`

import AvatarEgg from '../Avatar/AvatarEgg';
import styled from 'styled-components';
import { IWidgetMeeting, IWidgetTable } from '../../app';
import { LeadTextBlock } from '../../components/Type/Title';
import { colors } from '../../style/variables';
import { WhiteButton } from '../../components/Buttons/WhiteButton';
import { SecondaryVoteButtonSm } from '../../components/Buttons/SecondaryVoteButtonSm';
import { PrimaryVoteButton } from '../../components/Buttons/PrimaryVoteButton';
import { useContext } from 'preact/hooks';
import { AppContext } from '../../app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useVoting } from '../../hooks/useVoting'

import { TextBlock } from '../Type/Title'
import { LinkWithQuery } from '../LinkWithQuery';
import { Text } from 'preact-i18n';
import { useLocation, useNavigate } from 'react-router';

interface TableProps {
    table: IWidgetTable | undefined;
    meeting: IWidgetMeeting | null | undefined,
}

const ListTable = ({ table }: TableProps) => {

    const { widgetInfo } = useContext(AppContext)
    const { voteStudent, isVoting, didIVoteThisUser, voteTable } = useVoting()
    const navigate = useNavigate()
    const { search } = useLocation()

    const isMe = (user: any) => {
        return widgetInfo?.me.profile._id === user?._profileId
    }

    return (
        <TableContainer>
            <Workgroup>
                <FontAwesomeIcon className="icon" icon={['fas','users']}/>
                <Text id="list_table.team_number" fields={{tableNumber: table?.tableNumber}}></Text>
            </Workgroup>
            <ActionsContainer>
                <PrimaryVoteButton 
                onClick={() => voteTable()}
                voted={table?.voteData?.hasVoted}
                aria-label={<Text id="main_footer.like_team"></Text>}
                >
                    {
                        isVoting !== undefined
                        ? (<FontAwesomeIcon className="icon" icon={['fal', 'spinner']} spin />)
                        : (<FontAwesomeIcon className={table?.voteData?.hasVoted ? 'icon vote-icon active' : 'icon'} icon={table?.voteData?.hasVoted ? ['fas', 'heart'] : ['fal', 'heart']} />)
                    }
                    <Text id="main_footer.like_team"></Text>
                </PrimaryVoteButton>
            </ActionsContainer>
            <Table>
                <TableList>
                    {
                        table?.positions.map((item, i) => {
                            return (
                                <ListItem>
                                    <div className="user-data" onClick={() => navigate(`/user/${item._userId._id}` + search)}>
                                        <AvatarEgg
                                            title={`${item._userId.name} ${item._userId.lastname}`}
                                            src={item._userId.urlImage}
                                            size={38} 
                                            facilitator={table.facilitator == item._profileId}
                                            />
                                        <LeadTextBlock style={{ marginLeft: '10px', fontSize:'13px' }}>{item._userId.name} {item._userId.lastname}</LeadTextBlock>
                                    </div>
                                    {!isMe(item) && <SecondaryVoteButtonSm
                                        style={{ width: '118px', textTransform:'capitalize', letterSpacing:'0.01rem', fontSize:'12px' }}
                                        size={'sm'}
                                        title={`View ${item._userId.name} ${item._userId.lastname}`}
                                        voted={didIVoteThisUser(item._profileId)}
                                        // If selected, unselect when click
                                        disabled={didIVoteThisUser(item._profileId)}
                                        onClick={() => voteStudent(item._profileId)}
                                    >
                                        {isVoting && item._profileId === isVoting
                                            ? (
                                                <FontAwesomeIcon className="icon" style={{ margin: 0 }} icon={['fal', 'spinner']} spin />
                                            ) : (
                                                didIVoteThisUser(item._profileId) ? <Text id="actions.recognized" ></Text> : <Text id="actions.recognize"></Text>
                                            )}
                                    </SecondaryVoteButtonSm>}
                                </ListItem>
                            )
                        })
                    }
                </TableList>
            </Table>
            <div className="text">
                <TextBlock style={{color:'black'}}>
                <Text id="main_footer.tutorial_help"></Text>
                <LinkWithQuery to="/tutorial">
                    <Text id="main_footer.tutorial_link"></Text>
                </LinkWithQuery></TextBlock>
            </div>
        </TableContainer>
    )
}

export default ListTable

const Workgroup = styled.div`
    .icon{
        margin-right:5px;
    }
    text-align:center;
    padding:15px 0 0 0;
    font-size:12px;
    color:${colors.dhSecondary};
    letter-spacing:0.5px;
    text-transform:uppercase;
    font-weight:bold;
`


const ActionsContainer = styled.div`
    /* background-color:blue; */
    display:flex;
    flex-direction:row;
    width:100%;
    margin-top:25px;
    margin-bottom:15px;
    button{
        &:first-child{
            flex:1;
            margin-right:10px;
        }
    }
`

const BtnMeeting = styled(WhiteButton)`
    .icon{
        margin-right:0;
        font-size:22px;
        color: ${colors.dhSecondary} !important;
    }
    &:not(:disabled):hover{
        background-color: ${colors.dhSecondary} !important;
        .icon{
            color:white !important;
        }
    }
`

export const TableContainer = styled.div`
    padding:0px 30px;
    /* border:1px solid pink; */
    .text{
        text-align:center;
        padding:0 20px;
        margin-bottom:25px;
        h2{
            margin-bottom:0px;
        }   
        p{
            margin-top:5px;
            color:${colors.washedGreyEgg}
        }
    }
`

const Table = styled.div`
    width:100%;
    padding-bottom:0px;
    /* background-color:yellow; */
`

export const TableList = styled.ul`
    list-style-type:none;
    padding:0;
    margin:0;
    width:100%;
`

export const ListItem = styled.li`
    cursor:pointer;
    padding:8px 0;
    margin:0;
    display:flex;
    align-items:center;
    justify-content:space-between;
    text-transform:capitalize;
    &:not(:last-child){
        border-bottom:1px solid ${colors.separadorLineas};
    }
    .user-data{
        display:flex;
        align-items:center;
        flex-basis:60%;
    }
`

import MainLayout from '../../layouts/MainLayout';
import SecondaryHeading from '../../components/SecondaryHeading';
import StatsResume from '../../components/StatsResume';
import { useContext, useEffect, useState } from 'preact/hooks';
import { AppContext, TablePos } from '../../app';
import ReactGA from 'react-ga'
import { Text } from 'preact-i18n';

interface Props {

}

const UserView = (props: Props) => {

    const { widgetInfo, } = useContext(AppContext)
    const [selectedUser, setSelectedUser] = useState<TablePos | undefined>(undefined)

    useEffect(() => {
        // Get if from url
        const path = window.location.pathname.split('/')
        const uid = path[2]
        // Filter this user from widgetInfo table positions
        const user: TablePos[] | undefined = widgetInfo?.table.positions.filter((student) => student._userId._id === uid)
        if (user) {
            // Set it local
            setSelectedUser(user[0])
        }

        ReactGA.pageview('/profile-page');

    }, [])

    // Checks if widget user voted this particular user and pass down a bool to mark button as active 
    const didIVoteThisUser = () => {
        return widgetInfo?.me.voteData._profileToId === selectedUser?._profileId
    }

    const isMe = () => {
        return widgetInfo?.me.profile._id === selectedUser?._profileId
    }

    return (
        <MainLayout>
            <SecondaryHeading name={isMe() ? <Text id="profile.my_profile"/> : `${selectedUser?._userId.name} ${selectedUser?._userId.lastname}`} />
            {selectedUser && <StatsResume
                isMe={isMe()}
                isUserVoted={didIVoteThisUser()}
                isUserFacilitator={widgetInfo?.table.facilitator === selectedUser?._profileId}
                user={selectedUser}
            />}
        </MainLayout>
    )
}

export default UserView

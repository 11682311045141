import axios from 'axios';
import { useState, useContext } from 'preact/hooks';
import { AppContext } from '../app';
import ReactGA from 'react-ga'

interface VotingContext {
    voteStudent: (profileId: string | 'omit') => void,
    voteTable: () => void,
    isVoting: string | undefined,
    didIVoteThisUser: (profileId: string) => boolean
}

// This hook handles voting within all widget voting buttons,
// It handles voting an specific profileId or 'omit' in mandatory vote view case. 
export const useVoting = (): VotingContext => {

    // Used to show spinners while posting vote.
    const [isVoting, setIsVoting] = useState<string | undefined>(undefined)
    // Used to fetch main widget info after voting
    const { widgetInfo, fetchInfo } = useContext(AppContext)

    // Takes a profileId and votes student
    const voteStudent = async (profileId: string, algorithmToVote?: string) => {
        try {
            setIsVoting(profileId)
            await axios.post(`${process.env.PREACT_APP_WIDGET_API}/v1/vote`, profileId === 'omit' ? {
                type: 'blank',
                _algorithmId: algorithmToVote ? algorithmToVote : widgetInfo?.algorithm?._id,
            } : {
                _profileToId: profileId,
                _algorithmId: algorithmToVote ? algorithmToVote : widgetInfo?.algorithm?._id,
            })
        } catch (error) {
            console.log('Error recognizing student', error)
        } finally {
            // Gets localstorage, and awaits to fetch data to refresh voted stud, then disable spinner
            fetchInfo()
            setIsVoting(undefined)

            ReactGA.event({
                category: 'Student Recognition',
                action: profileId === 'omit' ? 'Vote omited' : 'Vote emited'
            });
        }
    }

    const voteTable = async () => {
        setIsVoting('')
        const URL = `${process.env.PREACT_APP_WIDGET_API}/v1/vote/table`
        const body = { table: widgetInfo?.table.tableNumber }

        // If is voted, unvote. If is not voted, vote. 
        if (widgetInfo?.table.voteData.hasVoted) {
            await axios.delete(URL, {data: { table: body.table } });
        } else {
            await axios.post(URL, body)
        }
        // Then fetch widgetinfo again to update heart status
        fetchInfo()
        setIsVoting(undefined)

        ReactGA.event({
            category: 'Table Recognition',
            action: 'Table vote emited'
        });
        
    }

    const didIVoteThisUser = (profileId: string) => {
        return widgetInfo?.me.voteData._profileToId === profileId
    }

    return {
        voteStudent,
        voteTable,
        isVoting,
        didIVoteThisUser
    }
}
import MainLayout from '../../layouts/MainLayout'
import SecondaryHeading from '../../components/SecondaryHeading'
import { useContext } from 'preact/hooks'
import { AppContext } from '../../app';
import styled from 'styled-components';
import { colors } from '../../style/variables';
import AvatarEgg from '../../components/Avatar/AvatarEgg'


const NotificationsView = () => {

    const { widgetInfo } = useContext(AppContext)

    return (
        <MainLayout>
            <SecondaryHeading name={widgetInfo?.me.name} />
            <NotificationsContainer>
                <NotificationsList>
                    <NotificationItem>
                        <div className="indicator">
                            <AvatarEgg title={'MB Barrio'} src={''} />
                        </div>
                        <div className="message">
                            Laura Rivas has improved her cooperation index by 15% this week. How are you doing?
                            <span className="timestamp"> 3 min</span>
                        </div>
                    </NotificationItem>
                    <NotificationItem>
                        <div className="indicator">
                            <AvatarEgg title={'MB Barrio'} src={''} />
                        </div>
                        <div className="message">
                            Recognize your teammates to improve future interactions
                            <span className="timestamp"> 3 min</span>
                        </div>
                    </NotificationItem>
                    <NotificationItem>
                        <div className="indicator">
                            <AvatarEgg title={'MB Barrio'} src={''} />
                        </div>
                        <div className="message">
                            Your cooperation index has increased 5% last week. Keep going!
                            <span className="timestamp"> 3 min</span>
                        </div>
                    </NotificationItem>
                    <NotificationItem>
                        <div className="indicator">
                            <AvatarEgg title={'MB Barrio'} src={''} />
                        </div>
                        <div className="message">
                            Laura Rivas has improved her cooperation index by 15% this week. How are you doing?
                            <span className="timestamp"> 3 min</span>
                        </div>
                    </NotificationItem>
                    <NotificationItem>
                        <div className="indicator">
                            <AvatarEgg title={'MB Barrio'} src={''} />
                        </div>
                        <div className="message">
                            Laura Rivas has improved her cooperation index by 15% this week. How are you doing?
                            <span className="timestamp"> 3 min</span>
                        </div>
                    </NotificationItem>
                    <NotificationItem>
                        <div className="indicator">
                            <AvatarEgg title={'MB Barrio'} src={''} />
                        </div>
                        <div className="message">
                            Laura Rivas has improved her cooperation index by 15% this week. How are you doing?
                            <span className="timestamp"> 3 min</span>
                        </div>
                    </NotificationItem>
                    <NotificationItem>
                        <div className="indicator">
                            <AvatarEgg title={'MB Barrio'} src={''} />
                        </div>
                        <div className="message">
                            Laura Rivas has improved her cooperation index by 15% this week. How are you doing?
                            <span className="timestamp"> 3 min</span>
                        </div>
                    </NotificationItem>
                </NotificationsList>
            </NotificationsContainer>
        </MainLayout>
    )
}

export default NotificationsView


const NotificationsContainer = styled.div`
    flex-grow:1;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    padding:20px 30px;
`

const NotificationsList = styled.div`
    display:flex;
    flex-direction:column;
`

const NotificationItem = styled.div`
    display:flex;
    padding:10px 0;
    align-items:center;
    .indicator{
        width:25%;
        /* background-color: aqua; */
        height:60px;
        display:flex;
        justify-content:center;
    }
    .message{
        flex:1;
        padding:0 0px 0 10px;
        font-size:14px;
        line-height:20px;
        .timestamp{
            color:${colors.washedGreyEgg}
        }
    }
    &:not(:last-child){
        border-bottom:1px solid ${colors.separadorLineas}
    }
`
import styled, { css } from "styled-components";

export const MainTitle = styled.h2`
    font-size:20px;
    color: #37474F;
    a{
        color: #37474F;
        text-decoration:none;
    }
`

export const SecondaryTitle = styled(MainTitle)`
    font-size:16px;
`

export const TextBlock = styled.p`
    font-size:12px;
    line-height:19px;
    color: #37474F;
    ${({ center }) => center && css`
        text-align:center;
    `}
`

export const LeadTextBlock = styled(TextBlock)`
    font-size:14px;
    line-height:20px;
`
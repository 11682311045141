import { useState, useRef, useEffect, useContext } from 'preact/hooks'
import { useNavigate, useLocation } from 'react-router-dom'
import MainLayout from '../../layouts/MainLayout'
import styled from 'styled-components';
import Slider from "react-slick";
import { MainTitle, TextBlock } from '../../components/Type/Title';
import { WhiteButton } from '../../components/Buttons/WhiteButton';
import Step0 from '../../assets/wizard/step0.png'
import Step1 from '../../assets/wizard/step1.png'
import Step1Pt from '../../assets/wizard/step1_pt.png'
import Step1En from '../../assets/wizard/step1_en.png'
import Step2 from '../../assets/wizard/step2.png'
import Step2Pt from '../../assets/wizard/step2_pt.png'
import Step2En from '../../assets/wizard/step2_en.png'
import Step3 from '../../assets/wizard/step3.png'
import Step4 from '../../assets/wizard/step4.png'
import Step6 from '../../assets/wizard/step6.png'
import { colors } from '../../style/variables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Text } from 'preact-i18n';
import Axios from 'axios';
import { AppContext } from '../../app'

const Tutorial = () => {

    const [step, setStep] = useState(1)
    const onboarding = useRef<Slider>()
    const lang = 'es-AR'
    const { widgetInfo, fetchInfo } = useContext(AppContext)
    const navigate = useNavigate()
    const { search } = useLocation()

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:false,
        arrows:false,
        afterChange: (current:number) => setStep(current)
    };

    const next = async () => {
        // Is current === last slide? asd
        if (step === 5) {
            completeTutorial()
            
        } else {
            onboarding?.current?.slickNext()
        }
    }

    const shouldShowPrev = () => {
        if(step !== 1){
            return true
        } else {
            return false
        } 
    }

    const completeTutorial = async () => {
        // Lógica para completar el tutorial
        try {
            const res = await Axios.patch<any>(`${process.env.PREACT_APP_WIDGET_API}/v1/user/completedTutorial`,{
                value: true
            },{
                timeout: 10000,
            })
        } catch (error) {
            console.log('Error patching.', error)
            widgetInfo?.me.tutorialWidgetCompleted === true
            // Patch local value on context
        } finally {
            await fetchInfo()
            navigate('/' + search)
        }
    }

    

    useEffect(() => {
        shouldShowPrev()
    },[step])


    return(
        <MainLayout>
            <TutorialContainer>
                <SliderContainer>
                    <Slider {...settings} ref={onboarding}>
                        <SliderItem>
                            <div className="img-container">
                                <img src={Step0} alt=""/>
                            </div>
                            <div className="text-content">
                                <MainTitle><Text id="tutorial.step0.title" /></MainTitle>
                                <TextBlock style={{fontSize:'15px'}} center><Text id="tutorial.step0.msg" /></TextBlock>
                            </div>
                            <div className="next-container">
                                <WhiteButton 
                                onClick={() => next()}
                                value={step}
                                style={{width:'100%'}}><Text id="tutorial.next" /></WhiteButton>
                            </div>
                        </SliderItem>
                        <SliderItem>
                            <div className="img-container">
                                {lang === 'es-AR' ? <img src={Step1} alt="Reconocer"/> : lang === 'pt-BR' ? <img src={Step1Pt} alt="Reconhecer"/> : <img src={Step1En} alt=""/>}
                            </div>
                            <div className="text-content">
                                <MainTitle><Text id="tutorial.step1.title" /></MainTitle>
                                <TextBlock style={{fontSize:'15px'}} center><Text id="tutorial.step1.msg" /></TextBlock>
                            </div>
                            <div className="next-container">
                                <WhiteButton 
                                onClick={() => next()}
                                value={step}
                                style={{width:'100%'}}><Text id="tutorial.next" /></WhiteButton>
                            </div>
                        </SliderItem>
                        <SliderItem>
                            <div className="img-container">
                                {lang === 'es-AR' ? <img src={Step2} alt="Me gusta este equipo"/> : lang === 'pt-BR' ? <img src={Step2Pt} alt="Eu gosto desta equipe"/> : <img src={Step2En} alt=""/>}
                            </div>
                            <div className="text-content">
                                <MainTitle><Text id="tutorial.step2.title" /></MainTitle>
                                <TextBlock style={{fontSize:'15px'}} center><Text id="tutorial.step2.msg" /></TextBlock>
                            </div>
                            <div className="next-container">
                                <WhiteButton 
                                onClick={() => next()}
                                value={step}
                                style={{width:'100%'}}><Text id="tutorial.next" /></WhiteButton>
                            </div>
                        </SliderItem>
                        <SliderItem>
                            <div className="img-container">
                                <img src={Step3} alt=""/>
                            </div>
                            <div className="text-content">
                                <MainTitle><Text id="tutorial.step3.title" /></MainTitle>
                                <TextBlock style={{fontSize:'15px'}} center><Text id="tutorial.step3.msg" /></TextBlock>
                            </div>
                            <div className="next-container">
                                <WhiteButton 
                                onClick={() => next()}
                                value={step}
                                style={{width:'100%'}}><Text id="tutorial.next" /></WhiteButton>
                            </div>
                        </SliderItem>
                        <SliderItem>
                            <div className="img-container">
                                <img src={Step4} alt=""/>
                            </div>
                            <div className="text-content">
                                <MainTitle><Text id="tutorial.step4.title" /></MainTitle>
                                <TextBlock style={{fontSize:'15px'}} center><Text id="tutorial.step4.msg" /></TextBlock>
                            </div>
                            <div className="next-container">
                                <WhiteButton 
                                onClick={() => next()}
                                value={step}
                                style={{width:'100%'}}><Text id="tutorial.next" /></WhiteButton>
                            </div>
                        </SliderItem>
                        <SliderItem>
                            <div className="img-container">
                                <img src={Step6} alt=""/>
                            </div>
                            <div className="text-content">
                                <MainTitle><Text id="tutorial.step5.title" /></MainTitle>
                                <TextBlock style={{fontSize:'15px'}} center><Text id="tutorial.step5.msg" /></TextBlock>
                            </div>
                            <div className="next-container">
                                <WhiteButton 
                                onClick={() => next()}
                                value={step}
                                active
                                style={{width:'100%'}}><Text id="tutorial.start" /></WhiteButton>
                            </div>
                        </SliderItem>
                    </Slider>
                </SliderContainer>
            </TutorialContainer>
            <TutorialNavigation>
                {shouldShowPrev() && <NavigationIcon onClick={() => onboarding.current.slickPrev()}>
                    <FontAwesomeIcon icon={['fal','arrow-left']}/>
                </NavigationIcon>}
                {<NavigationIcon style={{marginLeft:'auto'}} onClick={() => completeTutorial()}>
                    <FontAwesomeIcon icon={['fal','times']}/>
                </NavigationIcon>}
            </TutorialNavigation>
        </MainLayout>
    )
}

export default Tutorial


const TutorialNavigation = styled.div`
    position: absolute;
    top:0;
    left:0;
    width:100%;
    padding:20px; 
    display:flex;
    justify-content:space-between;
`

const NavigationIcon = styled.button`
    font-size:22px;
    color:${colors.darkGreyEgg};
    background:none;
    border:none;
    &:hover{
        opacity:.7;
    };
    cursor:pointer;
`

const TutorialContainer = styled.div`
    /* background-color:red; */
    /* height:100%; */
    padding:20px 30px;
    position:relative;
    
`

const SliderItem = styled.div`
    /* background-color:blue; */
    height:100% ;
    display:flex !important;
    flex-direction:column;
    align-items:center;
    text-align:center;
    .img-container{
        /* background-color:red; */
        display:flex;
        align-items:center;
        flex-grow: 1 !important;
        width: 100%;
        justify-content: center;
        img{
            width: auto;
            height: 160px;
            object-fit: cover;
            object-position: center center;
        }
    }
    .text-content{
        /* background-color:pink; */
        flex: 1 !important;
        padding:20px 0;
        p{
            color: #879DA7;
            line-height: 22px;
        }
    }
    .next-container{
        /* background-color:aqua; */
        width:95%;
        /* margin-top:30px; */
        margin-bottom:45px;
    }
`

const SliderContainer = styled.div`
    height:100%;
    .slick-slider{
        height:100%;
    }
    .slick-slide>div{
        /* background-color:brown; */
        height:100%;
    }
    .slick-list{
        height:100%;
    }
    .slick-track{
        height:100%;
    }
    .slick-dots{
        /* background-color:yellow; */
        margin-bottom:25px;
        li{
            padding:0;
            margin:0;
            button{
                height: auto;
            }
        }
        li button:before,
        li.slick-active button:before {
            color: transparent;
            opacity: 1;
        }
        li button:before{
            background-color: #E8E9EA;
            border: 2px solid #E8E9EA;
            border-radius: 50%;
            height: 5px;
            width: 5px;
        }
        li.slick-active button:before {
            border: 2px solid #37474F;
            background-color: #37474F;
        }
    }
`
import { createContext } from 'preact';
import {
    BrowserRouter,
    Routes,
    Route
  } from "react-router-dom";
import TableView from './routes/TableView/TableView'
import StatsView from './routes/StatsView/StatsView'
import MeetingView from './routes/MeetingView/MeetingView'
import ProfileView from './routes/ProfileView/ProfileView';
import './icons/library';
import { StateUpdater, useEffect, useState } from 'preact/hooks';
import UserView from './routes/UserView/UserView';
import MainLayout from './layouts/MainLayout'
import Axios from 'axios';
import 'react-circular-progressbar/dist/styles.css';
import MandatoryVoteView from './routes/MandatoryVoteView/MandatoryVoteView';
import NotificationsView from './routes/NotificationsView/NotificationsView';
import Tutorial from './routes/Tutorial/Tutorial'
import { BlueButton } from './components/Buttons/BlueButton'
import ReactGA from 'react-ga'

import { IntlProvider } from 'preact-i18n';
import spanish from './locales/es/translation.json';
import english from './locales/en/translation.json';
import portugues from './locales/pt/translation.json';
import { Text } from 'preact-i18n';


import "./assets/styles/slick.css"; 
import "./assets/styles/slick-theme.css";

export type ContextType = {
    widgetInfo: IWidgetInfo | undefined,
    fetchInfo: () => Promise<void>,
    isFetching: boolean,
    viewType: string,
    setViewType: StateUpdater<"list" | "table">
}

const defaultValues = {
    widgetInfo: undefined,
    fetchInfo: () => Promise.reject(null),
    isFetching: false,
    viewType: 'list',
    setViewType: () => {}
}

// Feeds info and functions to app
export const AppContext = createContext<ContextType>(defaultValues)

const App = () => {

    // Local loading
    const [ready, setReady] = useState<boolean | undefined>(false)
    // Used to know if data is fetched
    const [widgetReady, setWidgetReady] = useState<boolean | undefined>(undefined)
    // Main widget info from user/info
    const [widgetInfo, setWidgetInfo] = useState<IWidgetInfo | undefined>(undefined)
    // Bool used to show spinners on app
    const [isFetching, setIsFetching] = useState<boolean>(false)
    // Sync with platform setting
    const [mandatoryVote, setMandatoryVote] = useState<boolean>(false)
    // Sets main table view
    const [viewType, setViewType] = useState<'table' | 'list'>('list')
    // Bool if user is not assigned
    const [isAssigned, setIsAssigned] = useState<boolean | undefined>(undefined)

    const [definition, setDefinition] = useState<any>(spanish)
    
    // const { t } = useTranslation(['translation'])

    // Checks if there is a token
    useEffect(() => {
        // Fetchs token from url
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token')
        Axios.defaults.headers.common['Authorization'] = token
        let lang = queryParams.get('lang')

        switch(lang){
            case 'en-US':
                setDefinition(english)
                break
            case 'pt-BR':
                setDefinition(portugues)
                break
            default:
                setDefinition(spanish)
        }
        
        // Case first widget load
        if (token) {
            fetchInfo()
            setWidgetReady(true)
            // Case reload widget hot module
        } else {
            console.log('No token')
            setReady(true)
            setWidgetReady(false)
        }
    }, [])

    useEffect(() => {
        // Wait untill widget info is fullfiled and set Mandatory Vote 
        // popup according to shouldVote and mandatoryVote properties
        if (widgetInfo) {
            if(!widgetInfo.table){ 
                setIsAssigned(false)
            } else {
                setIsAssigned(true)
                setMandatoryVote(widgetInfo?.me.shouldVote && widgetInfo.config.mandatoryVote)
            }
        }
    }, [widgetInfo])

    useEffect(() => {
        // Log React.GA
        ReactGA.initialize('UA-141662578-1')
        ReactGA.pageview(window.location.pathname + window.location.search);
    },[])

    // Fetchs main endpoint data after checking if token is available
    const fetchInfo = async () => {
        if (!widgetInfo) { setReady(false) }
        setIsFetching(true)
        try {
            const res = await Axios.get<IWidgetResponse>(`${process.env.PREACT_APP_WIDGET_API}/v1/user/info`, { timeout: 10000 })
            setWidgetInfo(res.data.data)
            setReady(true)
        } catch (error) {
            setReady(true)
            setWidgetInfo(undefined)
            console.log('Error fetching.', error)
        } finally {
            // If fetchs or fails, set page ready anyway
            setReady(true)
            setIsFetching(false)
        }
    }

    const reload = () => fetchInfo()
    

    // Fetching
    if (!ready) return <IntlProvider definition={definition}><MainLayout centered={true}><div style={{textAlign:'center', padding:'0 40px'}}>
        <Text id="control.loading" />
    </div></MainLayout></IntlProvider>
    // If Fetching fails
    if (!widgetReady) return <IntlProvider definition={definition}><MainLayout centered={true}><div style={{textAlign:'center', padding:'0 40px'}}>
        <Text id="control.not_ready" />
    </div></MainLayout></IntlProvider>
    // If user is not assigned 
    if (!isAssigned) return (
        <IntlProvider definition={definition}>
            <MainLayout centered={true}>
                <div style={{textAlign:'center', padding:'0 40px'}}>
                    <Text id="control.not_assigned" />
                    <BlueButton aria-label="Retry" style={{margin:'20px auto 0 auto'}} onClick={() => reload()}>
                        <Text id="control.retry" />
                    </BlueButton>
                </div>
            </MainLayout>
        </IntlProvider>
    )
    // If shouldVote === true
    if (mandatoryVote && widgetInfo?.me.shouldVote) return (
        <IntlProvider definition={definition}>
        <AppContext.Provider value={{ 
            widgetInfo, 
            fetchInfo, 
            isFetching, 
            viewType ,
            setViewType
            }}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<MandatoryVoteView />} />
                </Routes>
            </BrowserRouter>
        </AppContext.Provider>
        </IntlProvider>
    )
    // If everything went well
    return (
        <div id="widget-egg">
            <IntlProvider definition={definition}>
                <AppContext.Provider value={{
                    widgetInfo, 
                    fetchInfo, 
                    isFetching,
                    viewType,
                    setViewType
                    }}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<TableView/>} />
                            <Route path="/user/:id" element={<UserView/>} />
                            <Route path="/meeting" element={<MeetingView/>} />
                            <Route path="/stats" element={<StatsView/>} />
                            <Route path="/profile" element={<ProfileView/>} />
                            <Route path="/notifications" element={<NotificationsView/>} />
                            <Route path="/tutorial" element={<Tutorial/>} />
                        </Routes>
                    </BrowserRouter>
                </AppContext.Provider>
            </IntlProvider>
        </div>
    )
}

export default App


export interface IWidgetInfo {
    algorithm?:{
        _id?: string,
        isMentor?: boolean
    },
    me: {
        // My info as widget user
        email: string,
        lastname: string,
        name: string,
        profile: {
            _id: string
        },
        shouldVote: boolean,
        stats: {
            assistance: AssistanceType,
            cooperationIndex: number,
            timesFacilitator: number,
            votes: number
        },
        urlImage: string,
        // My votes to another user on my own table
        voteData: {
            createdAt?: string,
            _profileFromId?: string,
            _profileToId?: string
        },
        _id: string,
        tutorialWidgetCompleted: boolean
    },
    meeting: IWidgetMeeting | null,
    // The table where I'm sitting on now
    table: IWidgetTable,
    config: {
        mandatoryVote: boolean
    },
    team: {
        course:{
            id: string,
            name: string
        },
        goal: {
            id:string,
            name:string
        },
        hq: {
            id: string,
            name:string
        }
    }
}

type AssistanceType = {
    timesAbsent: number,
    timesDelay: number,
    timesPresent: number,
}

export interface IWidgetResponse {
    data: {
        me: {
            email: string,
            lastname: string,
            name: string,
            profile: {
                _id: string
            },
            shouldVote: boolean,
            stats: {
                assistance: AssistanceType,
                cooperationIndex: number,
                timesFacilitator: number,
                votes: number
            },
            urlImage: string,
            voteData: {
                createdAt?: string,
                _profileFromId?: string,
                _profileToId?: string
            },
            _id: string,
            tutorialWidgetCompleted: boolean
        },
        meeting: IWidgetMeeting | null,
        table: IWidgetTable,
        config: {
            mandatoryVote: boolean
        },
        team: {
            course:{
                id: string,
                name: string
            },
            goal: {
                id:string,
                name:string
            },
            hq: {
                id: string,
                name:string
            }
        }
    }
}


export type TablePos = {
    number: number,
    // ProfileId is generally the identificator to make api calls
    _profileId: string,
    _userId: {
        lastname: string,
        name: string,
        statusMessage: string,
        urlImage: string,
        _id: string,
    }
}

export type IWidgetTable = {
    // Table facilitator
    facilitator: string,
    // Students on this table
    positions: TablePos[],
    tableNumber: number,
    // Status of my vote to this particular table
    voteData: {
        hasVoted: boolean,
        table?: number,
        _id?: string
    }
}


export interface IWidgetMeeting {
    type: string,
    urlLink: string
}

export interface IUserStatsResponse {
    data: {
        assistance: AssistanceType,
        connections: {
            lessConnected: Record<string, any>[],
            moreConnected: Record<string, any>[]
        },
        cooperationIndex: number,
        givenVotes: 3,
        performance: {
            performance: PerformanceType[]
        },
        receivedVotes: number,
        timesFacilitator: number,
        weeklyVariation: number,
    }
}

export interface IUserStats {
    assistance: AssistanceType,
    connections: {
        lessConnected: Record<string, any>[],
        moreConnected: Record<string, any>[]
    },
    cooperationIndex: number,
    givenVotes: 3,
    performance: {
        performance: PerformanceType[]
    },
    receivedVotes: number,
    timesFacilitator: number,
    weeklyVariation: number,
}


type PerformanceType = {
    day: string,
    value: number
}
import { colors } from "../../style/variables";
import styled from "styled-components";

export const IconButton = styled.button`
    background:none;
    border:none;
    font-size:1.5rem;
    color: ${colors.darkGreyEgg};
    height:30px;
    display:flex;
    justify-content:center;
    align-items:center;
`
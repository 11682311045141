import MainLayout from '../../layouts/MainLayout';
import { useContext, useEffect, useState } from 'preact/hooks';
import { AppContext } from '../../app';
import Axios from 'axios';
import styled from 'styled-components';
import { SecondaryTitle, LeadTextBlock, TextBlock } from '../../components/Type/Title'
import { BlueButton } from '../../components/Buttons/BlueButton'
import { WhiteButton } from '../../components/Buttons/WhiteButton'
import AvatarEgg from '../../components/Avatar/AvatarEgg';
import { colors } from '../../style/variables';
import { useLocation, useNavigate } from 'react-router-dom'
import Step5 from '../../assets/wizard/step5.png'
import ReactGA from 'react-ga'
import { Text } from 'preact-i18n';
import { LinkWithQuery } from '../../components/LinkWithQuery';

interface Props {

}

const UserView = (props: Props) => {

    const { widgetInfo } = useContext(AppContext)
    const [ready, setReady] = useState(false)
    const [check, setCheck] = useState(false)

    const navigate = useNavigate()
    const { search } = useLocation()

    useEffect(() => {
        checkMeeting()
        ReactGA.pageview('/meeting-join-view');
    }, [widgetInfo])

    const checkMeeting = async () => {
        
        ReactGA.event({
            category: 'Meetings',
            action: 'Checking if meeting is enabled'
        });

        try {
            const res = await Axios.post(`${process.env.PREACT_APP_WIDGET_API}/v1/user/present`, {})
            // If res === 200
            setCheck(true)
        } catch (error) {
            // If res === 400
            setCheck(false)
            // console.log('error checking meeting', error)
        } finally {
            setReady(true)
        }
    }

    const joinMeeting = () => {
        
        window.open(`${widgetInfo?.meeting?.urlLink}`)
        ReactGA.event({
            category: 'Meetings',
            action: 'Joining Meeting'
        });

    }

    if (!ready) return <MainLayout centered={true}><div><Text id="control.loading"></Text></div></MainLayout>

    return (
        <MainLayout>
            {/* <MainHeading me={widgetInfo?.me} /> */}
            <MeetingBlock>
                <img src={Step5} alt=""/>
                {/* <FontAwesomeIcon className="video-icon" icon={['fas', 'video']} /> */}
                <SecondaryTitle className="block-title"><Text id="meeting.title"/></SecondaryTitle>
                <LeadTextBlock className="lead-text" center style={{ marginBottom: '0' }}>
                    {
                        check ? (
                            <Text id="meeting.check_true"/>
                        ) : (
                            <Text id="meeting.check_false"/>
                        )
                    }
                </LeadTextBlock>
                {check && <MeetingMembers>
                    {
                        widgetInfo?.table.positions.map((student, idx) => (
                            <LinkWithQuery to={`/user/${student._userId._id}`}>
                                <AvatarEgg
                                    title={`${student._userId.name} ${student._userId.lastname}`}
                                    src={student._userId.urlImage}
                                    style={{
                                        zIndex: `${(1000 + idx)}px`,
                                        border: '5px solid white',
                                        borderRadius: '39px',
                                        marginRight: '-15px'
                                    }}
                                    size={37} />
                            </LinkWithQuery>
                        ))
                    }
                </MeetingMembers>}
                {/* {check && <TextBlock center style={{ padding: '0 20px' }}>compañer@s</TextBlock>} */}
                {check && <BlueButton
                    width={100}
                    // style={{ width: '100%' }} 
                    className="join-button"
                    onClick={() => joinMeeting()}><Text id="meeting.join"/></BlueButton>}
                <WhiteButton
                    width={100}
                    // style={{ width: '100%' }} 
                    className="cancel-button"
                    onClick={() => navigate('/' + search)}
                    style={!check && {marginTop:'20px'}}
                    >{check ? <Text id="meeting.cancel"/> : <Text id="meeting.back"/>}</WhiteButton>
            </MeetingBlock>
        </MainLayout>
    )
}

export default UserView

const MeetingBlock = styled.div`
    flex-grow:1;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    /* border:1px solid red; */
    padding:20px 30px;
    .block-title{
        margin-top:20px;
        margin-bottom:10px;
    }
    .lead-text{
        margin-top:0;
        padding:0 25px;
    }
    .video-icon{
        font-size: 2.5rem;
        color: ${colors.blueEgg};
    }
    .join-button{
        margin-top:15px;
        margin-bottom:10px;
    }
`

const MeetingMembers = styled.div`
    display: flex;
    margin:15px 0 0;
`

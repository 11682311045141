import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";

interface S3ReturnData {
    S3Upload: (file: Blob, filename: string) => Promise<string | undefined>
}

export const useS3Upload = (): S3ReturnData => {

    const S3Upload = async (file: Blob, filename: string) => {

        const AWS_BUCKET = 'test-bucket-mbarrionuevo'
        const AWS_REGION = 'us-east-2'

        const s3 = new S3Client({
            region: AWS_REGION,
            credentials: {
                secretAccessKey: 'EKvOFfx6zKcsGAYAEjqkpGOTGS5K9GNDUKSYom/e',
                accessKeyId: 'AKIA6J3SW3BJECDJMC44'
            }
        });

        let url;
        try {
            await s3.send(new PutObjectCommand({
                Key: filename,
                Bucket: AWS_BUCKET,
                ContentType: 'image/jpeg',
                Body: file,
                ACL: 'aws-exec-read'
            }));
            url = `https://${AWS_BUCKET}.s3.${AWS_REGION}.amazonaws.com/${filename}`
        } catch (err) {
            console.log("Error", err);
        }

        return url
    }

    return { S3Upload }
}
import styled from 'styled-components'
import { MainTitle } from './Type/Title'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from '../components/Buttons/IconButton';
import { useLocation, useNavigate } from 'react-router-dom';


interface IProps {
    name?: string | JSX.Element
}

const SecondaryHeading = ({ name }: IProps) => {

    const navigate = useNavigate()
    const { search } = useLocation()

    return (
        <Heading>
            <IconButton
                onClick={() => navigate('/' + search)}
                className="left"
                aria-label="Go Back"
            >
                <FontAwesomeIcon icon={['fal', 'arrow-left']} />
            </IconButton>
            <MainTitle className="middle">{name}</MainTitle>
            <IconButton
                onClick={() => console.log('do nothing')}
                className="right"
                aria-label="Hidden button"
            >
            </IconButton>
        </Heading>
    )
}

export default SecondaryHeading


const Heading = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:18px 25px;
    .left{
        width:15%;
    }
    .middle{
        width:70%;
        padding:0 20px;
        text-transform:capitalize;
        line-height: 1.6rem;
        text-align:center;
    }
    .right{
        width:15%;
    }
`

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'preact/hooks';
import { AvatarEggStyled } from './AvatarEggStyled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IAvatarEggProps {
    src: string,
    badge?: any,
    title?: string,
    facilitator?: boolean,
    hasVoted?: boolean,
    size?: number,
    style?: Record<string, any>,
    animate?: boolean,
    isMe?: boolean,
    handleFileSelect?: (e: any) => void,
    bgColor?: any,
    hasTooltip?:boolean
}

const AvatarEgg = (props: IAvatarEggProps) => {

    const { src, title, hasTooltip } = props;
    // Ref to img. Used to replace current.src when requests fails. 
    const avatarImgRef = useRef<any>(null)
    // Read and set src on mount
    const [source, setSource] = useState<string | undefined>(src)
    // Ref to input, upload img.
    const fileInputRef = useRef<HTMLInputElement>(null)

    const [showTooltip, setShowTooltip] = useState(false)

    // Update src if src changes
    useEffect(() => {
        setSource(src)
    }, [src])

    return (
        <AvatarEggStyled title={title && title} {...props} onMouseOver={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
            <input style={{ display: 'none' }} type="file" onChange={props.handleFileSelect} ref={fileInputRef} />
            <div className="main">
                {!source ? (
                    <span className="gravatar">{title?.split(' ').map((word) => word.slice(0, 1)).join('').toUpperCase().substring(0,2)}</span>
                ) : (
                    <img src={source} alt={title}
                        ref={avatarImgRef}
                        // if fails to load src, set to undefined to show user-icon
                        onError={() => { avatarImgRef.current.src = setSource(undefined) }}
                    />
                )}
            </div>
            {props.facilitator && <div className="badge"><FontAwesomeIcon className="icon" icon={['fas','medal']} /></div>}
            {hasTooltip && showTooltip && <div className="tooltip">{title}</div>}
        </AvatarEggStyled>
    )
}

export default AvatarEgg;

